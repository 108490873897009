export var FormFieldOptionType;
(function (FormFieldOptionType) {
  FormFieldOptionType[FormFieldOptionType["MapsTo"] = 0] = "MapsTo";
  FormFieldOptionType[FormFieldOptionType["Label"] = 1] = "Label";
  FormFieldOptionType[FormFieldOptionType["SubLabel"] = 2] = "SubLabel";
  FormFieldOptionType[FormFieldOptionType["Placeholder"] = 3] = "Placeholder";
  FormFieldOptionType[FormFieldOptionType["Required"] = 4] = "Required";
  FormFieldOptionType[FormFieldOptionType["DropdownOptionsJSON"] = 5] = "DropdownOptionsJSON";
  FormFieldOptionType[FormFieldOptionType["DefaultValue"] = 6] = "DefaultValue";
  FormFieldOptionType[FormFieldOptionType["Hidden"] = 7] = "Hidden";
  FormFieldOptionType[FormFieldOptionType["CheckboxOptionsJSON"] = 8] = "CheckboxOptionsJSON";
  FormFieldOptionType[FormFieldOptionType["RadioOptionsJSON"] = 9] = "RadioOptionsJSON";
})(FormFieldOptionType || (FormFieldOptionType = {}));