export var FormFieldInternalId;
(function (FormFieldInternalId) {
  FormFieldInternalId[FormFieldInternalId["CustomerFirstNameField"] = 0] = "CustomerFirstNameField";
  FormFieldInternalId[FormFieldInternalId["CustomerLastNameField"] = 1] = "CustomerLastNameField";
  FormFieldInternalId[FormFieldInternalId["CustomerEmailField"] = 2] = "CustomerEmailField";
  FormFieldInternalId[FormFieldInternalId["CustomerPhoneField"] = 3] = "CustomerPhoneField";
  FormFieldInternalId[FormFieldInternalId["CustomerStreet1Field"] = 4] = "CustomerStreet1Field";
  FormFieldInternalId[FormFieldInternalId["CustomerStreet2Field"] = 5] = "CustomerStreet2Field";
  FormFieldInternalId[FormFieldInternalId["CustomerCityField"] = 6] = "CustomerCityField";
  FormFieldInternalId[FormFieldInternalId["CustomerCountryField"] = 7] = "CustomerCountryField";
  FormFieldInternalId[FormFieldInternalId["CustomerRegionField"] = 8] = "CustomerRegionField";
  FormFieldInternalId[FormFieldInternalId["CustomerPostalCodeField"] = 9] = "CustomerPostalCodeField";
})(FormFieldInternalId || (FormFieldInternalId = {}));