export var FormComponentType;
(function (FormComponentType) {
  FormComponentType[FormComponentType["CustomText"] = 0] = "CustomText";
  FormComponentType[FormComponentType["CustomNumber"] = 1] = "CustomNumber";
  FormComponentType[FormComponentType["CustomRadio"] = 2] = "CustomRadio";
  FormComponentType[FormComponentType["CustomCheckbox"] = 3] = "CustomCheckbox";
  FormComponentType[FormComponentType["CustomLargeText"] = 4] = "CustomLargeText";
  FormComponentType[FormComponentType["Name"] = 5] = "Name";
  FormComponentType[FormComponentType["Email"] = 6] = "Email";
  FormComponentType[FormComponentType["PhoneNumber"] = 7] = "PhoneNumber";
  FormComponentType[FormComponentType["Address"] = 8] = "Address";
  FormComponentType[FormComponentType["Appointment"] = 9] = "Appointment";
  FormComponentType[FormComponentType["HorizontalRule"] = 10] = "HorizontalRule";
  FormComponentType[FormComponentType["CustomDropdown"] = 11] = "CustomDropdown";
  FormComponentType[FormComponentType["TextBlock"] = 12] = "TextBlock";
})(FormComponentType || (FormComponentType = {}));